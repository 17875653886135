<template>
  <div data-cy="alert-message" class="alert" :class="type">
    <div v-if="icon" class="icon">
      <icon-base :icon="icon" :icon-color="iconColor" />
    </div>
    <div class="alert-message">
      <slot />
    </div>
    <div v-if="$slots.actions" class="alert-actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType, ComponentOptions } from "vue";
import { computed } from "vue";
import { COLORS } from "@/helpers/constants";
const props = defineProps({
  type: {
    type: String,
    default: "success"
  },
  icon: {
    type: [String, Object] as PropType<
      string | (ComponentOptions | ComponentOptions["setup"])
    >
  }
});

const iconColor = computed(() => {
  let color;
  switch (props.type) {
    case "success":
      color = COLORS[0];
      break;
    case "warning":
      color = COLORS[5];
      break;
    case "error":
      color = COLORS[2];
      break;
    default:
      color = COLORS[0];
      break;
  }
  return `rgb(${color})`;
});
</script>

<style scoped>
.alert {
  @apply flex flex-col items-start lg:flex-row p-4 space-y-4 lg:space-y-0 lg:space-x-4 text-headline;
}
.alert-message {
  @apply flex-auto space-y-1;
}
.alert-actions {
  @apply flex items-center space-x-2;
}

.success {
  @apply bg-green-50;
}
.error {
  @apply bg-red-50;
}
.warning {
  @apply bg-yellow-50;
}
</style>
